/**
 * main module
 */

(function ($) {

    "use strict";

    var map = $('#map'),
        mapGoogle = null,
        centerPosition = null;

    function init() {

        var mapOptions = {
            zoom: 10,
            center: new google.maps.LatLng(55.7642144, 37.6027399),
            zoomControl: !0,
            scrollwheel: !1,
            styles: [
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#e9e9e9"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f5f5f5"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#dedede"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#ffffff"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#333333"
                        },
                        {
                            "lightness": 40
                        }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#fefefe"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                }
            ]
        };

        mapGoogle = new google.maps.Map(document.getElementById('map'), mapOptions);

        var iconBase = 'img/';
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(55.7632544, 37.6015399),
            map: mapGoogle,
            icon: iconBase + 'geomark.png'
        });

        centerPosition = mapGoogle.getCenter();
    }

    if (map.length && map.length > 0) {
        google.maps.event.addDomListener(window, 'load', init);
    }

    $(window).resize(function () {
        if (typeof google !== 'undefined') {
            google.maps.event.trigger(mapGoogle, "resize");
            mapGoogle.setCenter(centerPosition);
        }
    });

    $('.fullMenu').click(function (e) {
        e.preventDefault();
    });

    siteModule.blockToggle({
        elSelector: '.faqBox__trigger',
        elTarget: '.faqBox__answer',
        type: 'slideToggle',
        parent: '.faqBox__item',
        callback: function (el, target, state) {
            if (state === 'opened') {
                el.addClass('open');
            } else {
                el.removeClass('open');
            }
        }
    });

    siteModule.blockToggle({
        elSelector: '.ratesBox__trigger',
        elTarget: '.ratesBox__item',
        type: 'slideToggle',
        parent: '.ratesBox__one',
        callback: function (el, target, state) {
            if (state === 'opened') {
                el.addClass('open');
            } else {
                el.removeClass('open');
            }
        }
    });

    $('.siteContent table').cardtable();

    // aim menu call

    siteModule.aimMenu($(".dropdown-menu"));
    //siteModule.aimMenu($(".dropdown-menu-phone"));


    // mobile menu copy
    $('.mobileMenu').html($('.navigation--header').clone().find('ul > li > ul').remove().end().find('.navigation__mobile').remove().end().html());

    // phone mask

    $('input.phone').mask('+7 (999) 999-9999', {
        autoclear: true, placeholder: "__________", completed: function () {
        }
    });

    if (typeof messageObj !== 'undefined') {
        siteModule.createModal('#dynamicOverlay', messageObj.message || '', messageObj.title || '');
    }

    $('.goTo').on('click', function (e) {

        e.preventDefault();

        var goToElement = $($(this).attr('data-to'));

        if (goToElement && goToElement.length && goToElement.length > 0) {
            $("html,body").scrollTo(goToElement.offset().top - 100);
        }
    });


})(jQuery);
var siteModule = (function (global) {

    "use strict";

    var _timer = null,
        _customCallbacks = [],
        _winObj = $(global),
        _winWidth = _winObj.width(),
        _winHeight = _winObj.height(),
        _bodyObj = $('body'),
        _overlayTimer = null,
        _bluredElements = $('.mainContentWrapper');

    /**
     * проверить что val строка
     * @param val - string
     * @returns {boolean}
     * @private
     */

    var _isString = function (val) {
        return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
    };


    /**
     * отфиксили скрол страницы
     * @private
     */

    var _stopScrollingPage = function () {
        document.documentElement.style.overflowY = 'hidden';
        document.body.style.overflowY = 'scroll';
    };

    /**
     * зафиксили скрол страницы
     * @private
     */

    var _startScrollingPage = function () {
        document.documentElement.style.overflowY = 'auto';
        document.body.style.overflowY = 'auto';
    };


    /**
     * устанавливает обработчик при клике
     * для вызова оверлея для страницы about -- класс для ссылки overlayHandler
     * @private
     */

    var _setOverlayHandler = function () {

        _bodyObj.on('click', '.overlayHandler', function (e) {
            e.preventDefault();
            _createMobalWindow(this);
        });

    };


    /**
     * создаем модальные окна
     *
     * @param target
     * @param message -- сообщение
     * @param mainTitle -- заголовок оверлея*
     *
     */

    var _createMobalWindow = function (target, message, mainTitle) {

        _bluredElements.addClass('blurFiler');

        var title = $(target).attr('data-title') || '',
            overlayWindow = $(target).attr('data-overlay') || '#dynamicOverlay',
            $overlayContent = $('.mainOverlay__contentIn'),
            $overlayTitle = $('.mainOverlay__headerInner');

        if (typeof mainTitle !== 'undefined' && $overlayTitle && $overlayTitle.length && $overlayTitle.length > 0) {
            $overlayTitle.text(mainTitle);
        }

        if (typeof message !== 'undefined' && $overlayContent && $overlayContent.length && $overlayContent.length > 0) {
            $overlayContent.html(message);
        }

        $(overlayWindow).fadeToggle(100, function () {

            var phoneMaskInput = $('.mainOverlay__feedbackInput').find('input');

            phoneMaskInput.val('').unmask().mask('+7 (999) 999-9999', {
                autoclear: true, placeholder: "__________", completed: function () {
                }
            });

            phoneMaskInput.focus();
        });

    };


    /**
     * закрываем все оверлеи
     * @private
     */


    var _closeAllOverlays = function () {

        _bodyObj.on('click', '.closeMenuButton', function (e) {

            e.preventDefault();

            $(this).parents('.overlayBox').fadeToggle(100);
            _bluredElements.removeClass('blurFiler');

        });

    };


    /**
     * добавить callback функцию для _init модуля
     * @param func - callback function
     * @param immediately - выполнить немедленно при загрузке
     * @private
     */

    var _addCallback = function (func, immediately) {
        if (typeof func === 'function') {
            if (immediately === true) {
                (func)();
            } else {
                _customCallbacks.push(func);
            }
        }
    };

    /**
     *
     * @param boxForAttach -- jquery селектор бокса где лежит input и label
     * @returns
     */

    var _attachInputPlaceholder = function (boxForAttach) {

        var box = boxForAttach ? $('' + boxForAttach) : $('.forms__placeholder'),
            phoneMask = '+7 (___) ___-____';

        if (box.length && box.length > 0) {

            box.each(function (index, item) {

                var label = $(item).children('label'),
                    input = $(item).children('input');

                if (!input.length || input.length <= 0) {
                    return;
                }

                $(item).on('focus propertychange change click keyup input paste', function (e) {

                    if (input.val().trim().length <= 0) {

                        label.css({
                            'opacity': 0,
                            'height': '1em',
                            'top': '5px'
                        });

                        if (input.attr('name').toString() === 'phone') {
                            input.unmask();
                            input.mask('+7 (999) 999-9999', {
                                autoclear: true, placeholder: "__________", completed: function () {
                                }
                            });
                        }

                        input.focus();
                    }
                });

                input.on('blur', function () {

                    if (input.attr('name').toString() === 'phone') {
                        if (input.val() === phoneMask) {
                            input.val('');
                        }
                    }

                    if ($(this).val().trim().length <= 0) {
                        label.css({
                            'opacity': 1,
                            'height': '',
                            'top': ''
                        });
                    }
                });
            });
        }
    };

    var _activateSubmenu = function (row) {

        var $row = $(row),
            submenuId = $row.data("submenuId"),
            $submenu = $("#" + submenuId);

        //$row.addClass('activeMenu');

        $submenu.stop().slideDown(50);
    };

    var _deactivateSubmenu = function (row) {

        var $row = $(row),
            submenuId = $row.data("submenuId"),
            $submenu = $("#" + submenuId);

        $submenu.stop().slideUp(50);

        //$row.removeClass('activeMenu');
    };

    var _attachCatalogMenu = function (Jobject) {

        if (Jobject && Jobject.length && Jobject.length > 0) {

            Jobject.menuAim({
                activate: _activateSubmenu,
                deactivate: _deactivateSubmenu,
                submenuDirection: 'below',
                exitMenu: function () {
                    return true;
                }
            });

            Jobject.find('li').click(function (e) {
                //e.stopPropagation();
            });
        }

    };

    /**
     * прикрепить видео проигрывание
     * @private
     */


    var _attachVideoPlayEvent = function () {

        $(".indexReklBox__video").on("click", function (e) {

            var close = $("#closeBtn");

            e.preventDefault(), $("#playerID").empty(), close.on("click", function (e) {
                $(this).removeClass('rotateArrow');
                e.preventDefault(), $("#playerID").empty(), $("#overlay").fadeOut(400);
            });

            var t = $(this).data('video'),
                i = _winWidth - 80,
                n = _winHeight - 80;

            if ($(window).width() < 768) {
                i = i + 50;
            }

            $("#overlay-inner").css({
                "margin-left": -(i / 2),
                "margin-top": -(n / 2)
            }).width(i).height(n).children("#playerID").width(i).height(n), $("#overlay").fadeIn(400, function () {
                close.addClass('rotateArrow');
                var player = new Uppod({
                    auto: "play",
                    m: "video",
                    uid: "playerID",
                    file: t,
                    poster: ""
                });
            })
        });
    };

    /**
     * разбить строку по разрядам
     * @param str
     * @returns {строку разбитую по разрядам}
     * @private
     */

    var _splitPrice = function (str) {
        return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
    };


    /**
     *
     * @param value -- строка значение
     * @param minVal -- мин значение
     * @param maxVal -- максимальное значение
     * @private
     */

    var _toInteger = function (value, minVal, maxVal) {

        var minValue = minVal || 0,
            maxValue = maxVal || Number.MAX_VALUE,
            currentValue;

        if (value.match(/[^0-9]/g)) {
            currentValue = value.replace(/[^0-9]/g, '');
        } else {
            currentValue = +value;
        }

        if (currentValue < minValue) {
            currentValue = minValue;
        }

        if (currentValue >= maxValue) {
            currentValue = maxValue;
        }

        return currentValue;
    };


    /**
     *
     * @param selector -- '.someSelector';
     * @param options  поддрежива.тся оригинальные опции из UI
     *          {
        *          slideCallback: function -- slide callback когда у нас есть привязынные поля так как мы переписываем стандартный метод slide,
        *          range : boolean //
        *          isIntVals: преобразовать все значение в int для связанных со слайдером полей,
        *
        *          updateElements {
        *           from:  // если range true -- откуда
        *           to:// если range true -- куда
        *          }
        *
     *          }
     * @returns {boolean}
     * @private
     */

    // переписать все свойства через define properties

    var _createUiSlider = function (selector, options) {

        if (!(this instanceof _createUiSlider)) {
            return new _createUiSlider(selector, options);
        }

        if (!(selector && $(selector).length && $(selector).length > 0)) {
            return false;
        }

        if (!$(selector).slider) {
            return false;
        }

        var elOptionsDefault = {
            min: $(selector).attr('data-min') ? +$(selector).attr('data-min') : 0,
            max: $(selector).attr('data-max') ? +$(selector).attr('data-max') : 100,
            step: $(selector).attr('data-step') ? +$(selector).attr('data-step') : 1
        };

        $.extend(options, elOptionsDefault); // расширяем обехкт по дефолту

        var minVal = +options.min,
            maxVal = +options.max,
            from,
            to,
            sliderSelector = $(selector),
            transformToInt = options.isIntVals && options.isIntVals === true;


        if (options.updateElements) {

            from = options.updateElements.from ? options.updateElements.from : null; //

            if (!from) {
                return false;
            }

            if ($(from) && from.length > 0 && from.val && transformToInt) {

                $(from).on('blur keyup input click', function () {
                    $(this).val(_toInteger($(this).val()));
                });

            }

            if (options.range && options.range === true) {

                to = options.updateElements.to ? options.updateElements.to : null;

                if (!to) {
                    return false;
                }

                if ($(to) && to.length > 0 && to.val && transformToInt) {

                    $(to).on('blur keyup input click', function () {
                        $(this).val(_toInteger($(this).val()));
                    });

                }
            }

            // обратная связь если есть input

            var slideCallback = options.slideCallback || null;

            options.slide = function (event, ui) {

                if (ui.values) {

                    from.val(ui.values[0]);
                    to.val(ui.values[1]);

                } else if (ui.value) {

                    from.val(ui.value);

                }

                if (slideCallback && typeof slideCallback === 'function') {
                    slideCallback.apply(ui, [event, ui]);
                }

            };

        }

        // start ui slider

        sliderSelector.slider(options);

        $(from).on('change blur', function (e) {

            var el = $(this),
                currentVal = _toInteger(el.val(), minVal, maxVal);

            if (currentVal >= maxVal) {
                currentVal = minVal;
            }

            if (currentVal >= +$(to).val()) {
                currentVal = +$(to).val();
            }

            el.val(currentVal);

            if (options.range && options.range === true) {
                sliderSelector.slider("values", [currentVal, +$(to).val()]);
            } else {
                sliderSelector.slider("value", currentVal);
            }

        });

        if ($(to) && $(to).length && $(to).length > 0) {

            $(to).on('change blur', function (e) {

                var el = $(this);

                var currentVal = _toInteger(el.val(), minVal, maxVal);

                if (currentVal < +$(from).val()) {
                    currentVal = +$(from).val();
                }

                el.val(currentVal);

                sliderSelector.slider("values", [+$(from).val(), currentVal]);

            });

        }

        this.instance = sliderSelector.slider("instance");

    };

    /**
     * @param options --
     *          elSelector -- селектор элемента к которому прикрепляетя событие
     *          elTarget -- селектор элемента блока который сворачивается разворачивается
     *          type -- доступный jquery метод -- slideToggle fadeToggle etc
     *          speed -- скорость
     *          parent -- селектор род. элемента для разделения анимации
     *
     *          callbackAnimation -- фукнции по окончанию анимации
     *          callback -- функция при клике на элемент
     */

    var _blockToggle = function (options) {

        // заплатка -- наверное мождно и получше сделать

        if (typeof options === 'undefined' || typeof options !== 'object') {
            return;
        }

        var elSelector = options.elSelector && $(options.elSelector).length &&
        $(options.elSelector).length > 0 ? $(options.elSelector) : null;

        var elTarget = options.elTarget && $(options.elTarget).length &&
        $(options.elTarget).length > 0 ? $(options.elTarget) : null;

        var speed = options.speed ? options.speed : 400,
            type = typeof options.type !== 'undefined' ? options.type : 'toggle';

        if (!(elTarget && elSelector) || typeof elTarget[type.toString()] === 'undefined') {
            return;
        }

        elSelector.click(function (e) {

            e.preventDefault();

            var useParent = options.parent ? options.parent : false,
                state = 'closest',
                targetEl = elTarget;


            if (useParent) {
                targetEl = $(this).parents(useParent.toString()).find(elTarget);
            }

            var animationEndCallback = function () {};

            if (!targetEl.is(':visible')) {
                state = 'opened';
            }

            $(this).data('state', state);

            if (typeof options.callback !== 'undefined' && typeof options.callback === 'function') {
                (options.callback).apply(this, [$(this), elTarget, state]);
            }

            if (typeof options.callbackAnimation !== 'undefined' && typeof options.callbackAnimation === 'function') {
                animationEndCallback = options.callbackAnimation.bind(this, [state]);
            }

            targetEl[type.toString()].call(targetEl, speed, animationEndCallback);

        });

    };

    /**
     * уничтожаем уже повешенный скролл
     * @param currentSlimScrollEl
     * @private
     */

    var __destroySlimScroll = function (currentSlimScrollEl) {

        var $elem = currentSlimScrollEl;

        if ($elem) {

            $('.slimScrollDiv').removeAttr('style');
            $elem.removeAttr('style');

            if ($elem && $elem.length) {

                var events = jQuery._data($elem[0], "events") || null;

                $elem.slimScroll({
                    destroy: true
                });

                if (events) {
                    jQuery._removeData($elem[0], "events");
                }

            }

        }

    };

    /**
     * создаем скрол
     * @param element -- селектор
     * @param height -- нужная высота -- если не указаны высота окна (+-) смещение
     * @param offset -- смещение
     * @returns {boolean}
     * @private
     */

    var _createSlimScroll = function (element, height, offset) {

        var currentEl = $(element),
            scrollOffset = typeof offset !== 'undefined' ? parseInt(height, offset) : 0;

        if (!(currentEl.length || currentEl.length > 0)) {
            return false;
        }

        __destroySlimScroll(currentEl);

        var scrollHeight = typeof height !== 'undefined' ? parseInt(height, 10) : $(window).height() - 50 - 20,
            fullScrollHeight = scrollHeight + scrollOffset;

        currentEl.slimScroll({
            height: fullScrollHeight + 'px',
            size: '4px',
            position: 'right',
            color: '#fff',
            alwaysVisible: false,
            distance: '10px',
            start: 'top',
            railVisible: true,
            railColor: '#fff',
            railOpacity: 0.3,
            wheelStep: 6,
            allowPageScroll: false,
            disableFadeOut: true,
            borderRadius: "0",
            opacity: 0.6,
            touchScrollStep: 20
        });

    };


    /**
     * небольшая фабриа для слайдера
     * @param selector
     * @param options - опции оригинального swiper
     * @returns {*}
     */

    var _swiperFabric = function (selector, options) {

        var box = $(selector),
            currentOptions = options || {};

        if (typeof selector === 'undefined') {
            return false;
        }

        if (!(box || box.length || box.length > 0)) {
            return false;
        }

        return new Swiper(selector, currentOptions);

    };

    /**
     * обновить рамзеры окна при ресайзе
     * @private
     */

    var _updateWinSizes = function () {
        _winWidth = _winObj.width();
        _winHeight = _winObj.height();
    };

    /**
     * метод вызывается при ресайзе окна браузера
     * @private
     */

    var _update = function () {
        _updateWinSizes();
    };

    /**
     * прикрепить обработчик resize к окну
     * @private
     */

    var _winResizeAttachHandler = function () {
        $(window).on('resize', function () {
            clearTimeout(_timer);
            _timer = setTimeout(function () {
                _update();
                _timer = null;
            }, 200);
        });
    };

    /**
     * инизиализируем внутренние компоненты
     * @returns {_init} ссылка на тек объект
     * @private
     */

    var _init = function () {

        _attachInputPlaceholder();
        _attachVideoPlayEvent();
        _setOverlayHandler();
        _closeAllOverlays();

        return this;

    };

    /**
     * возращаем объект с публичными методами
     */

    return {
        createSlimScroll: _createSlimScroll,
        swiperFabric: _swiperFabric,
        blockToggle: _blockToggle,
        toInteger: _toInteger,
        addCallback: _addCallback,
        init: _init,
        aimMenu: _attachCatalogMenu,
        createModal: _createMobalWindow
    };

})(window).init();


